import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, throwError } from 'rxjs';
import { map, catchError, switchMap, finalize, tap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import * as Config from '../../config'

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private isLoadingSubject: BehaviorSubject<boolean>;
  // public fields

  isLoading$: Observable<boolean>;


  constructor(
    private router: Router,
    private http: HttpClient,
  ) {

    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();

  }



  loginWithEmailPassword(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    }

    let payload = { "email": data['email'], "password": data['password'] }

    return this.http.post<any>(Config.LOGIN_URL, payload, httpOptions)
      .pipe(
        map(res => {
          return res
        })
      )

  }

  getJwtToken() {
    return localStorage.getItem("access_token");
  }
  private getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }

  check_login_token(){

    return localStorage.getItem("access_token")


  }


  logout_cleaner(){
   // this.storage.remove("user_data")
    localStorage.removeItem('userData')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    return
  }

  logout() {


    console.log("Log this logout")
    let access_token=this.getJwtToken()
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    return this.http.post<any>(Config.LOGOUT,'',httpOptions)
    .pipe(
      map(res=>{
        console.log(res)
        if (res['status'] ==1){
          this.logout_cleaner()
          console.log('Cleared storage')
          console.log("about to show")

        }

        return res
      })
    )

  }



  private storeJwtToken(jwt: string) {
    localStorage.setItem("access_token", jwt);
  }


  refreshToken() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.getRefreshToken()
      })
    }
    return this.http.post<any>(Config.TOKEN_REFRESH_URL,'',httpOptions).pipe(
      tap((newtoken) => {
      this.storeJwtToken(newtoken.access_token);
    }))
  }

  handleError(error: HttpErrorResponse) {
    console.log(error)

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      return throwError(error.error.message)
    }
    else if(error.status === 401){


      return throwError(error.error.message)
    }
     else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.message}`);
        return throwError(error.error.message)
    }

  };



  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

}
