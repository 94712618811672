
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.serviceback';
//import { SnapcashAuth } from './snapcashApi/snapcashAuth';
//import { App } from 'ionic-angular';
import { appMsgs } from './messages';





@Injectable()
export class AppServices {
    constructor(
        private authService: AuthService,
        private appmsg: appMsgs,
        private router : Router
    ) { }

    custome_http_error_handler(err: any,txntype='') {



        if (err.status == 403) {
            ////  Forbiddedn//// returned if Token is not correct

            this.appmsg.presentPopAlert(err.error.message, "You are not allowed to access this service.","error")
        }

        else if (err.status == 401) {
            //// User session Expired //

           //// this.appmsg.presentPopAlertLogout("Expired", err.error.message).then(confirm => {
               // if (confirm) {

                    this.authService.logout().subscribe((res: any) => {
                        ////  Send User to Dashboard Here ///

                       this.router.navigateByUrl('/auth/login');


                    })

               // }
           // })
        }
        else if (err.status == 0) {

            this.appmsg.presentToast("No internet connection", 15000)
        }

        else if (err.status == 500) {
            this.appmsg.presentToast("An error occured","error")
        }
        else if(err.status==400){

            if(txntype=='form'){
                this.ServerFormError(err)

            }
            else{
                this.appmsg.presentToast(err.error.message,"error")
            }
        }
        else if(err.status==409){

              this.appmsg.presentPopAlert("Error",err.error.message,"error")

      }
        else {
          this.appmsg.presentPopAlert("Error",err.error.message,"error")
        }

    }


    ServerFormError(err){
      console.log("Na server")
      if(err.error.form_errors){
        /// Actaull form message  from masmellow//




              let error_messages=[]
              for (let msg of err.error.form_errors){

                error_messages.push(msg)

              }

                let msgs="<ul>"

                for(let err of error_messages){
                msgs=msgs+`<li>${err}</li>`
                }
                msgs=msgs+'</ul>'
                console.log(msgs)

                this.appmsg. presentPopAlert('Error in your data',msgs,"error")

        }
        else{
          this.appmsg.presentPopAlert("Error",err.error.message,"error")
        }

      }




}
