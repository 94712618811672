import { Injectable} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'

//const Swal = require('sweetalert2')


@Injectable()
export class appMsgs{
  isLoading = false;

constructor(private toastr: ToastrService){

}

 async presentToast(message,status,duration=5000,position="bottom"){
 console.log("Toae will show")
 if (status=='error'){
 this.toastr.error('error', message, {
    timeOut: duration,
  });
 }
 else if(status=='success'){
  this.toastr.success('error', message, {
    timeOut: duration,
  });
 }

 else if(status=='info'){
  this.toastr.info('info', message, {
    timeOut: duration,
  });

 }

 else{
  this.toastr.info('warning', message, {
    timeOut: duration,
  });

 }

  }

 async presentPopAlert(title,msg,status){
 /// status == error, succrss
  Swal.fire({
    icon: status,
    title: title,
    text: msg,
    //footer: '<a href>Why do I have this issue?</a>'
  })


  }


  async presentLoadingPoptop(content='processing...',timeout=20000){
    Swal.fire({
      title: 'Processing...',
      html: 'Please wait',
      timerProgressBar: true,
      backdrop: true,
      toast:true,
      allowOutsideClick:false,
      allowEscapeKey:false,
      position:"top-end",
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })

  }


async presentLoadingPop(content='processing...',timeout=20000){
    Swal.fire({
      title: content,
      html: 'Please wait',
      timerProgressBar: true,
      backdrop: true,
      //toast:true,
      allowOutsideClick:false,
      allowEscapeKey:false,
      onBeforeOpen: () => {
        Swal.showLoading()
      }
    })

  }


  async dismissLoadingPop() {

    Swal.hideLoading()
    Swal.close()


  }



 async presentPopAlertLogout(title,subtitle): Promise<boolean>{
  let resolveFunction: (confirm: boolean) => void;
  let promise = new Promise<boolean>(resolve => {
    resolveFunction = resolve;
  });

  Swal.fire({
    title: 'Are you sure?',
    text: title,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Logout'
  }).then((result) => {
    if (result.isConfirmed) {
      resolveFunction(true)
    }
  })
  return promise;
}


 async confirmationAlert(title: string,message: string) {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  swalWithBootstrapButtons.fire({
    title: title,
    text: message,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Proceed',
    cancelButtonText: 'Cancel',
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed) {
      swalWithBootstrapButtons.fire(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      )
    } else if (
      /* Read more about handling dismissals below */
      result.dismiss === Swal.DismissReason.cancel
    ) {
      swalWithBootstrapButtons.fire(
        'Cancelled',
        'Your imaginary file is safe :)',
        'error'
      )
    }
  })

}



}
