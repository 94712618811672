import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { HeaderComponent } from './components/layout/header/header.component';
//import { SidemenuComponent } from './components/layout/sidemenu/sidemenu.component';
//import { FooterComponent } from './components/layout/footer/footer.component';
//import { AuthComponent } from './modules/auth/auth.component';
//import { CustomersComponent } from './pages/customers/customers.component';
import { AppServices } from './services/appServices';
import { appMsgs } from './services/messages';
import { authInterceptorProviders } from './__helpers/auth.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './modules/_services/auth.service';
import { LoansComponent } from './pages/loans/loans.component';
import { LayoutComponent } from './pages/layout/layout.component';
import { DynamicScriptLoaderService } from './services/dynamicscriptLoader';
//import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { PagesComponent } from './pages/pages.component';
import  * as $ from "jquery"
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertModule } from 'ngx-bootstrap/alert';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CdkTableExporterModule } from 'cdk-table-exporter';

@NgModule({
  declarations: [
    AppComponent,


   // LayoutComponent,
    //PagesComponent,
   // LoansComponent,
    //AuthComponent,
   // CustomersComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }), // ToastrModule added
    SweetAlert2Module.forRoot(),
    AlertModule.forRoot(),
    AccordionModule.forRoot(),
    CdkTableExporterModule
  ],
  providers: [
    authInterceptorProviders,
    AppServices,
    appMsgs,
    AuthService,
    DynamicScriptLoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
