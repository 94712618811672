import { Injectable } from '@angular/core';

interface Scripts {
  name: string;
  src: string;
}

///https://medium.com/@zainzafar/angular-load-external-javascript-file-dynamically-3d14dde815cb

export const ScriptStore: Scripts[] = [

  //{ name: 'ravejs-remote', src: 'https://rave-api-v2.herokuapp.com/flwv3-pug/getpaidx/api/flwpbf-inline.js' },
 // {name:'paystack-js', src:'https://js.paystack.co/v1/inline.js'},
 // { name: 'ravejs-local', src: '../assets/js/rave-inline.js' }
 { name: 'app-min-local', src: 'assets/dist/js/app.min.js' },
 { name: 'app-init-local', src: 'assets/dist/js/app.init.js' },
 { name: 'app-style-local', src: 'assets/dist/js/app-style-switcher.js' },
 { name: 'scroller-local', src: 'assets/libs/perfect-scrollbar/dist/perfect-scrollbar.jquery.min.js' },
 { name: 'sparkline-local', src: 'assets/extra-libs/sparkline/sparkline.js' },
 { name: 'waves-local', src: 'assets/dist/js/waves.js' },
 { name: 'sidebar-local', src: 'assets/dist/js/sidebarmenu.js' },
 { name: 'mycustom', src: 'assets/dist/js/customehenry.js'},
 { name: 'app-custom-local', src: 'assets/dist/js/custom.min.js' },
 { name: 'dashboardscript1', src: 'assets/libs/chartist/dist/chartist.min.js' },
 { name: 'dashboardscript2', src: 'assets/dist/js/pages/chartist/chartist-plugin-tooltip.js' },
 { name: 'dashboardscript3', src: 'assets/dist/js/pages/dashboards/dashboard4.js' },
 { name: 'dashboardscript4', src: 'assets/libs/gaugeJS/dist/gauge.min.js' },
 { name: 'dashboardscript5', src: 'assets/dist/js/pages/dashboards/dashboard2.js' },
 { name: 'magnific-popimage', src: 'assets/libs/magnific-popup/dist/jquery.magnific-popup.min.js' },
 { name: 'meg-init', src: 'assets/libs/magnific-popup/meg.init.js' },


];


declare var document: any;

@Injectable()
export class DynamicScriptLoaderService {

  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {

        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  //IE
            script.onreadystatechange = () => {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    this.scripts[name].loaded = true;
                    resolve({script: name, loaded: true, status: 'Loaded'});
                }
            };
        } else {  //Others
            script.onload = () => {
                this.scripts[name].loaded = true;
                resolve({script: name, loaded: true, status: 'Loaded'});
            };
        }
        script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
        document.getElementsByTagName('head')[0].appendChild(script);

    });
  }

}
