import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './modules/_services/auth.guard';
import { LoansComponent } from './pages/loans/loans.component';
//import { AuthGuard } from './modules/auth/_services/auth.guard';

export const routes: Routes = [
  {
      path: 'auth',
      loadChildren: () =>
     import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },

 // {
  //  path: 'auth/loginx',
   // loadChildren: () =>
   //   import('./pages/login/login.module').then((m) => m.LoginModule),
  //},


  //{
   // path: 'customers',
  //  canActivate: [AuthGuard],
  //  loadChildren: () =>
 //     import('./pages/customers/customers.module').then((m) => m.CustomersModule),
 // },

 {
  path: '',
  canActivate: [AuthGuard],
  loadChildren: () =>
    import('./pages/layout.module').then((m) => m.LayoutModule),
},

  { path: '**', redirectTo: 'errors/404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
