import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS} from '@angular/common/http';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
//import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { AuthService } from '../modules/_services/auth.service';
//import { AuthService } from '../modules/auth/_services/auth.service';

///https://angular-academy.com/angular-jwt/

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(public authService: AuthService, private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.authService.getJwtToken()) {
      request = this.addToken(request, this.authService.getJwtToken());
    }
    console.log("Interceptor about to run")
    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401) {
        console.log('i saw a 401')
        return this.handle401Error(request, next, error);
      } else {

        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
        }
        //window.alert(errorMessage);
        return throwError(error);

        ///return throwError(error);
      }
    }));
  }

  private addToken(request: HttpRequest<any>, token: string,is_newtoken: boolean=false) {


    /// Check if authorization
    console.log("payload",request)
    if(request.headers.get('Authorization')){
      console.log('I  saw an authorization token already ',token)
      if(is_newtoken){
     //   return request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        return request.clone({
          setHeaders: {
            'Authorization': `Bearer ${token}`
          }
        });
      }
      else{
         return request.clone();
      }

    }
  else{
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler, err) {

    if (err.error.error_slug == "token_expired") {
      if (!this.isRefreshing) {
        this.isRefreshing = true;
        this.refreshTokenSubject.next(null);
        console.log("Calling the token refresh service")
        return this.authService.refreshToken().pipe(
          switchMap((token: any) => {
            this.isRefreshing = false;
            //this.refreshTokenSubject.next(token.jwt);
            this.refreshTokenSubject.next(token.access_token);
            console.log('the  new added token is n',token.access_token)
            return next.handle(this.addToken(request, token.access_token,true));
            // return next.handle(this.addToken(request, token.jwt));
          }));

      } else {
        console.log("I saw a refreshing status")
        return this.refreshTokenSubject.pipe(
          filter(token => token != null),
          take(1),
          switchMap(jwt => {
            return next.handle(this.addToken(request, jwt.access_token,true));
          }));
      }
    }
   else if(err.error.error_slug == "refresh_token_expired"){
     console.log('NOW GUY')
      this.isRefreshing = false;
      this.refreshTokenSubject.next(null);
      this.authService.logout_cleaner()
      this.router.navigateByUrl('login')

      return throwError(err.error.message);
      //return throwError(err);

    }

    else {

      //  the refresh did not return 200 //
      //const error = err.error.message || err.statusText;
      this.isRefreshing = false;
      this.refreshTokenSubject.next(null);
      this.authService.logout_cleaner()

      this.router.navigateByUrl('auth/login')

      return throwError(err);
      //return throwError(err.error.message);
      console.log('will do redirect here man')
      ///this.router.navigateByUrl('auth')

    }

  }
}
export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
];
