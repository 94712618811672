import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DynamicScriptLoaderService } from './services/dynamicscriptLoader';




@Component({
 //selector: 'app-root',
 selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'CredBooth';
  ///scriptnames=['app-min-local','app-init-local','app-style-local','scroller-local','sparkline-local','waves-local','sidebar-local','app-custom-local']

  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private router: Router,
    private dynamicScriptLoader: DynamicScriptLoaderService
  ) {}


  ngOnInit() {
    //this.fetchscripts()

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
       // this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        //setTimeout(() => {
          //document.body.classList.add('page-loaded');
        //}, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }



  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
