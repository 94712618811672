
export const appCurreny="₦"
//export const ENDPOINT="http://127.0.0.1:50011/internal/"
export const ENDPOINT="https://apps.credbooth.com/admins/internal/"
export const LOGIN_URL=ENDPOINT+"auth"
export const REGISTER_ENDPOINT=ENDPOINT+'register'
export const LOGOUT=ENDPOINT+"logout"
export const TOKEN_REFRESH_URL=ENDPOINT+"refreshtoken"

/// ADMIN RELATED ////
export const FETCH_CUSTOMERS=ENDPOINT+"customers"
export const FETCH_CUSTOMER_DOCUMENT=ENDPOINT+"customersdocuments"    /// all customers document with doc type filter
export const ACTION_CUSTOMER_DOCUMENT=ENDPOINT+"actioncustomerdocument/"
export const FETCH_ONE_CUSTOMER_DOCUMENT=ENDPOINT+"getcustomerdocuments"   ///One csutomer document with filter

export const  FETCH_LOAN_HISTORY=ENDPOINT+"loanhistory"
export const  FETCH_LOAN_REPAYMENT=ENDPOINT+"loanrepayment"
export const  FETCH_CUSTOMER_LOANS=ENDPOINT+"fetchcustomerloans"
export const  ADD_BANK_PAYMENT=ENDPOINT+"adminaddbankrepayment"
export const  ADMIN_ACTION_PENDING_REPAYMENT=ENDPOINT+"resolverepayment"


export const FETCH_CUSTOMER_DASHBOARD_DATA=ENDPOINT+"customerdashboarddata"
export const FETCH_LOAN_DASHBOARD_DATA=ENDPOINT+"adminloandashboard"

export const Confirm_Email=ENDPOINT+"confirmemail"
export const  CheckProfileRequirements=ENDPOINT+"checkaccountrequirement"
export const  UPDATE_PROFILE=ENDPOINT+"updateprofile"
export const UpdateDOB=ENDPOINT+"updatedob"
export const FETCH_USERBANK_ACCOUNTS=ENDPOINT+'getbankaccounts'
export const ADD_BANKACCOUNT=ENDPOINT+'addbankaccount'
export const Confirm_BANKACCOUNT=ENDPOINT+'confirmbankaccount'
export const Password_Reset_Request=ENDPOINT+"resetpasswordrequest"
export const Password_Reset_Response=ENDPOINT+"resetpasswordresponse"


export const FETCH_USER_INFO=ENDPOINT+"fetchuserinfo"
export const FETCH_STATE=ENDPOINT+'fetchstates'
export const FETCH_BANKS=ENDPOINT+'fetchbanks'

///card related //
export const FETCH_CARDS=ENDPOINT+"getusercards"
export  const CARD_PRECHECK=ENDPOINT+"cardprecheck"
export const CARDPAYMENT_CALLBACK=ENDPOINT+"addcardcallback/"
export const GET_CARD_ADD_TXN=ENDPOINT+"generatecardref"
export const CANCEl_CARD_ADD=ENDPOINT+"cancelcardaddrequest"
export const DELETE_CARD=ENDPOINT+"deletecard"


/// Loans service ///
export const  LOAN_PRECEHCK=ENDPOINT+"creditprecheck"
export const  FETCH_LOAN_CATEGORY=ENDPOINT+"getloancategories"
export const  PROCESS_CREDIT=ENDPOINT+"loanrequest"
export const  CONFIRM_CREDIT_PROCESS=ENDPOINT+"confirmcredit"


/// Repayments //
export const  FETCH_PENDING_LOANS=ENDPOINT+"unpaiduserloans"
export const  FETCH_CARD_REPAYMENT_DATA=ENDPOINT+"addcardrepayment"
export const  FETCH_CARD_REPAYMENT_CALLBACK=ENDPOINT+"repaymentcallback"
export const  PAYWITH_TOKEN=ENDPOINT+"paywithtoken"


/// dashboard //
export const  FETCH_DASHBOARD_LOAN=ENDPOINT+"getdashboardloan"


/// content ///
export const FETCH_FAQ=ENDPOINT+"getfaq"
export const UPDATE_FAQ=ENDPOINT+"updatefaq/"
export const ADD_FAQ=ENDPOINT+"addfaq/"
export const DELETE_FAQ=ENDPOINT+"deletefaq"
export const FETCH_Term=ENDPOINT+"getterms"
export const Update_Term=ENDPOINT+"updateterms/"
