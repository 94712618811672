import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpErrorResponse } from '@angular/common/http';
import * as Config from '../config'
import { take, tap, delay, switchMap, map,catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userIsAuthenticated = false

  constructor(
    private http: HttpClient,
    private router:Router
  ) { }


  getJwtToken() {
    return localStorage.getItem("access_token");
  }
  private getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }

  check_login_token(){

    return localStorage.getItem("access_token")


  }

  Register(payload){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    }
    return this.http.post<any>(Config.REGISTER_ENDPOINT,payload,httpOptions)
    .pipe(
      map(res=>res)
    )

  }

  loginWithEmailPassword(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    }

    let payload = { "email": data['email'], "password": data['password'] }

    return this.http.post<any>(Config.LOGIN_URL, payload, httpOptions)
      .pipe(
        map(res => {
          return res
        })
      )

  }

  getUserInfo(){
    return this.http.get<any>(Config.FETCH_USER_INFO)
      .pipe(
        map(res => {
          return res
        })
      )
  }

  UpdateUserProfile(payload){
    return this.http.post<any>(Config.UPDATE_PROFILE, payload)
      .pipe(
        map(res => {
          return res
        })
      )
  }

  getStateList(){
    return this.http.get<any>(Config.FETCH_STATE)
      .pipe(
        map(res => {
          return res
        })
      )
  }

  checkProfileRequirement(){

    return this.http.get<any>(Config.CheckProfileRequirements)
    .pipe(
      map(res=>res)
    )

  }


  UpdateDOB(payload){

    return this.http.post<any>(Config.UpdateDOB,payload)
    .pipe(
      map(res=>res)
    )

  }

  GetBankAccounts(){
    return this.http.get<any>(Config.FETCH_USERBANK_ACCOUNTS)
    .pipe(
      map(res=>res)
    )
  }

  SaveNewBank(data){
    return this.http.post<any>(Config.ADD_BANKACCOUNT,data)
    .pipe(
      map(res=>res)
    )
  }

 ConfirmBankAccount(data){
    return this.http.post<any>(Config.Confirm_BANKACCOUNT,data)
    .pipe(
      map(res=>res)
    )
  }

  getBankList(){
    return this.http.get<any>(Config.FETCH_BANKS)
    .pipe(
      map(res=>res)
    )
  }

  passwordResetRequest(data){
    return this.http.post<any>(Config.Password_Reset_Request,data)
    .pipe(
      map(res=>res)
    )
  }

  passwordResetResponse(data){
    return this.http.post<any>(Config.Password_Reset_Response,data)
    .pipe(
      map(res=>res)
    )
  }


  logout_cleaner(){
    localStorage.remove("user_data")
    localStorage.removeItem('user_data')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    return
  }

  logout() {
    let access_token=this.getJwtToken()
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }

    return this.http.post<any>(Config.LOGOUT,'',httpOptions)
    .pipe(
      map(res=>{
        console.log(res)
        if (res['status'] ==1){
          this.logout_cleaner()
          console.log('Cleared storage')
        }

        return res
      })
    )

  }



VerifyEmail(data){
  return this.http.post<any>(Config.Confirm_Email,data)
  .pipe(
    map(result=>result)
  )

}

  private storeJwtToken(jwt: string) {
    localStorage.setItem("access_token", jwt);
  }





  refreshToken() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.getRefreshToken()
      })
    }
    return this.http.post<any>(Config.TOKEN_REFRESH_URL,'',httpOptions).pipe(
      tap((newtoken) => {
      this.storeJwtToken(newtoken.access_token);
    }))
  }

  handleError(error: HttpErrorResponse) {
    console.log(error)

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      return throwError(error.error.message)
    }
    else if(error.status === 401){


      return throwError(error.error.message)
    }
     else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.message}`);
        return throwError(error.error.message)
    }

  };





}
